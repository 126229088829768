<template>
    <el-dialog title="備註修改"
               v-model="show"
               :show-close="false"
               :close-on-click-modal="false"
               :destroy-on-close="true"
               width="480px"
               center>
        <div v-if="showDiv === 1">
            <div class="row-div">您已選擇<el-link type="primary"
                         @click="showDiv=2"
                         href="#"
                         :underline="false">{{selectionInfo.length}}個網域</el-link>
            </div>
            <div class="row-div">備註: <el-input v-model="note"
                          class="note-input"
                          placeholder="請輸入備註"></el-input>
            </div>
            <div style="text-align: center;">
                <el-button @click="closeDialog">取消</el-button>
                <el-button @click="onSubmit"
                           type="primary">確定</el-button>
            </div>
        </div>
        <div v-if="showDiv === 2">
            <el-scrollbar class="scrollbar">
                <el-table :data="selectionInfo"
                          stripe
                          style="width: 100%">
                    <el-table-column prop="domain"
                                     label="網域名稱">
                    </el-table-column>
                </el-table>
            </el-scrollbar>
            <div style="text-align: center;">
                <el-button @click="showDiv=1">返回</el-button>

            </div>
        </div>
    </el-dialog>
</template>

<script>
import { updateNotes } from '../../../api/index'
export default {
    props: {
        updateNotesDialogVisible: {
            type: Boolean,
            default: false,
        },
        selectionInfo: {},
        selectionId: {},
        callback: {
            type: Function,
            default: () => {},
        },
    },
    watch: {
        updateNotesDialogVisible() {
            this.show = this.updateNotesDialogVisible
        },
    },
    data() {
        return {
            show: this.updateNotesDialogVisible,
            note: '',
            showDiv: 1, // 1表單 2列表
        }
    },
    methods: {
        onSubmit() {
            let data = {
                domain_ids: this.selectionId,
                note: this.note,
            }
            updateNotes(data)
                .then((res) => {
                    if (res.code == 200) {
                        this.$message.success(res.message)
                        this.callback()
                    } else {
                        this.$message.error(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        closeDialog() {
            this.$emit('close-dialog')
        },
    },
}
</script>
<style lang="scss" scoped>
.row-div {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
    font-size: 16px;
}
.scrollbar {
    height: 300px;
    padding: 10px;
}
.note-input {
    padding-left: 10px;
    width: 180px;
}
</style>