<template>
    <el-dialog title="DNS修改"
               v-model="dnsVisible"
               destroy-on-close
               :close-on-click-modal="false"
               :show-close="false"
               width="480px"
               center>
        <div v-if="showDiv === 1">
            <div class="row-div">您已選擇<el-link type="primary"
                         @click="showDiv=2"
                         href="#"
                         :underline="false">{{selectionInfo.length}}個網域</el-link>
            </div>
            <div class="row-div">
                <el-form :model="dnsForm"
                         ref="dnsForm"
                         class="dns-form"
                         label-width="100px">
                    <el-form-item v-for="(dns, index) in dnsForm.dns"
                                  :label="'NS' + (index+1)"
                                  :key="dns.key"
                                  :prop="'dns.' + index + '.value'"
                                  :rules="{
      required: true, message: '不能為空', trigger: 'blur'
    }">
                        <div class="dns-item">
                            <el-input v-model="dns.value"
                                      class="dns-input"></el-input>
                            <el-button @click.prevent="removeDns(dns)"
                                       v-if="dnsForm.dns.length > 2"
                                       icon="el-icon-delete"
                                       circle></el-button>
                        </div>
                    </el-form-item>
                    <el-form-item style="text-align: start;">
                        <el-button @click="addDns"
                                   type="primary"
                                   plain
                                   class="dns-input">新增伺服器名稱</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div style="text-align: center;">
                <el-button @click="closeDialog">取消</el-button>
                <el-button @click="confirmSend()"
                           type="primary">確定</el-button>
            </div>
        </div>
        <div v-if="showDiv === 2">
            <el-scrollbar class="scrollbar">
                <el-table :data="selectionInfo"
                          stripe
                          style="width: 100%">
                    <el-table-column prop="domain"
                                     label="網域名稱">
                    </el-table-column>
                </el-table>
            </el-scrollbar>
            <div style="text-align: center;">
                <el-button @click="showDiv=1">返回</el-button>

            </div>
        </div>
        <div v-if="showDiv === 3">
            <div class="row-div">
                <el-form :model="dnsForm"
                         class="dns-form"
                         label-width="100px">
                    <el-form-item v-for="(dns, index) in dnsForm.dns"
                                  :label="'NS' + (index+1)+':'"
                                  :key="dns.key"
                                  :prop="'dns.' + index + '.value'">
                        <div class="dns-item">
                            <el-input v-model="dns.value"
                                      class="dns-input"
                                      readonly></el-input>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <div style="text-align: center;">
                <el-button @click="showDiv = 1">取消</el-button>
                <el-button @click="onSubmit"
                           type="primary">確定修改</el-button>
            </div>
        </div>
        <div v-if="showDiv === 4">
            <div class="row-div"
                 v-if="dnsLoading">更新中請勿關閉
            </div>
            <div class="row-div"
                 v-else>DNS修改完成
            </div>
            <div v-loading="dnsLoading"
                 v-if="dnsLoading"></div>
            <div style="text-align: center;"
                 v-if="!dnsLoading">
                <el-button @click="closeDialog"
                           type="primary">確定</el-button>
            </div>

        </div>
    </el-dialog>
</template>

<script>
import { nameserverModify, checkInterruptCode, nameserverReport } from '../../../api/index'
import { dateFormat } from '@/utils/dateFormat.js'
export default {
    props: {
        selectionInfo: {},
        selectionId: {},
        dnsDialogVisible: { default: false },
    },
    watch: {
        dnsDialogVisible() {
            this.dnsVisible = this.dnsDialogVisible
        },
    },
    data() {
        return {
            dnsVisible: this.dnsDialogVisible, //dns更新彈窗
            showDiv: 1, // 1表單 2列表 3列表確認 4詢問中斷碼
            dnsForm: {
                dns: [
                    {
                        value: '',
                    },
                    {
                        value: '',
                    },
                ],
            },
            dnsArray: [], //dns陣列
            dnsLoading: false, //dns更新中
            interruptCode: '', //dns中斷碼
            logId: '', //下載檔案的ID
            timer: '', //每五秒訪問一次
        }
    },
    methods: {
        confirmSend() {
            this.$refs['dnsForm'].validate((valid) => {
                if (valid) {
                    this.dnsArray = []
                    this.showDiv = 3
                    this.dnsForm.dns.forEach((element) => {
                        this.dnsArray.push(element.value)
                    })
                }
            })
        },
        onSubmit() {
            let data = {
                domain_ids: this.selectionId,
                nameservers: this.dnsArray,
            }
            nameserverModify(data)
                .then((res) => {
                    if (res.code == 300) {
                        this.interruptCode = res.data.interrupt_code
                        this.logId = res.data.log_id
                        this.dnsLoading = true
                        this.showDiv = 4
                        this.timer = setInterval(() => {
                            this.checkDns()
                        }, 5000)
                    } else {
                        this.$message.error(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        //檢查DNS更新是否完成
        checkDns() {
            checkInterruptCode({ interrupt_code: this.interruptCode }).then((res) => {
                if (res.code == 300) {
                    if (res.data.is_syncing == 'N') {
                        this.dnsLoading = false
                        this.reportExcel()
                        clearInterval(this.timer)
                    }
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        //下載report
        reportExcel() {
            nameserverReport({ log_id: this.logId }).then((res) => {
                this.excelTake(res, 'DNS更新' + dateFormat(new Date()))
            })
        },
        removeDns(item) {
            var index = this.dnsForm.dns.indexOf(item)
            if (index !== -1) {
                this.dnsForm.dns.splice(index, 1)
            }
        },
        addDns() {
            this.dnsForm.dns.push({
                value: '',
                key: Date.now(),
            })
        },
        closeDialog() {
            this.$emit('close-dialog')
        },
        //接收excel
        excelTake(res, title) {
            const xlsx = 'application/vnd.ms-excel'
            const blob = new Blob([res], { type: xlsx })
            const fileReader = new FileReader()
            let _this = this
            fileReader.readAsText(blob, 'utf-8')
            fileReader.onload = function () {
                if (res.type === 'application/json') {
                    if (JSON.parse(fileReader.result).code == 500) {
                        _this.$message.error('請勾選匯出的項目')
                    }
                } else {
                    const a = document.createElement('a') // 轉換完成，建立一個a標籤用於下載
                    a.download = `${title}.xlsx`
                    a.href = window.URL.createObjectURL(blob)
                    a.click()
                    a.remove()
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.row-div {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
    font-size: 16px;
}
.scrollbar {
    height: 300px;
    padding: 10px;
}
.dns-form {
    width: 400px;
}
.dns-input {
    width: 210px;
    margin-right: 5px;
}
.dns-item {
    display: flex;
}
.dns-check-area {
    display: flex;
}
</style>