<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 網域列表
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <el-form ref="formDomain"
                     :inline="true"
                     :model="formInline"
                     class="demo-form-inline">
                <el-form-item label="域名:"
                              prop="domain">
                    <el-input v-model="formInline.domain"
                              placeholder="請輸入域名"></el-input>
                </el-form-item>
                <el-form-item label="網域來源:"
                              prop="source_id">
                    <el-select v-model="formInline.source_id"
                               multiple
                               collapse-tags
                               placeholder="請選擇"
                               @change='changeSelect'>
                        <el-option v-for="item in sourceList"
                                   :key="item.value"
                                   :label="item.text"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="域名到期日:">
                    <el-date-picker v-model="formInline.datetime"
                                    type="daterange"
                                    :shortcuts="shortcuts"
                                    range-separator="至"
                                    start-placeholder="開始日期"
                                    end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="域名狀態:"
                              prop="status">
                    <el-select v-model="formInline.status"
                               multiple
                               collapse-tags
                               placeholder="請選擇"
                               @change='changeSelect'>
                        <el-option v-for="item in statusList"
                                   :key="item.value"
                                   :label="item.text"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="分享給:">
                    <el-input v-model="formInline.share_to"
                              placeholder="請填入已分享的商戶ID"></el-input>
                </el-form-item>
                <el-form-item label="來源註記:">
                    <el-input v-model="formInline.remark"
                              placeholder="請填入來源註記"></el-input>
                </el-form-item>
                <el-form-item label="搜尋備註:">
                    <el-input v-model="formInline.note"
                              placeholder="請填入備註"></el-input>
                </el-form-item>
            </el-form>
            <el-row>
                <el-col :span="24"
                        style="    text-align: center;">
                    <el-button type="primary"
                               @click="handleSearch">搜尋</el-button>
                    <el-button @click="resetForm">清除</el-button>
                </el-col>
            </el-row>
            <el-row style="padding-top:15px">
                <el-col class="count-row">
                    <div>當前 <span class="span-color-primary">{{tableData.length}}</span> 筆, 總共 <span class="span-color-primary">{{totalCount}}</span> 筆, 已勾選 <span class="span-color-error">{{selectionInfo.length}}</span> 筆
                        <el-button size="mini"
                                   @click="resetSelect()">清除勾選</el-button>
                    </div>
                    <div>
                        <!-- 1.6說要取消只留管理站 -->
                        <!-- <el-button type="danger"
                                   @click=" openNamecheapDialog">Namecheap DNS指向更新</el-button> -->
                        <el-button type="success"
                                   @click="exporDomian">網域列表匯出</el-button>
                    </div>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24"
                        class="pd-top-bottom"><span>批次上傳備註:</span>
                    <input ref="excel-upload-input"
                           class="excel-upload-input"
                           type="file"
                           accept=".xlsx, .xls"
                           @change="batchImportClick">
                    <el-button size="medium"
                               style="margin-left:10px"
                               @click="excelEx"
                               round>範本下載</el-button>
                    <el-button type="info"
                               size="medium"
                               @click="batchImportAdds"
                               round>批次匯入</el-button>
                    <el-button size="medium"
                               type="primary"
                               @click="exportRemark"
                               round>匯出</el-button>
                    <el-button size="medium"
                               type="warning"
                               @click="updateNotes"
                               round>備註修改</el-button>
                    <el-button size="medium"
                               type="warning"
                               @click="updateDNS"
                               round>DNS修改</el-button>
                </el-col>
            </el-row>
            <el-alert title="DNS修改，每五分鐘自動重新同步一次域名。請先「清除勾選」後，選擇網域來源『Clro』，在進行『DNS修改』"
                      :closable="false"
                      show-icon
                      type="info">
            </el-alert>
            <div v-loading="listLoading">
                <el-table ref="domainTable"
                          :data="tableData"
                          @sort-change="handleSortChange"
                          @selection-change="handleSelectionChange"
                          :row-key="getRowKey"
                          border
                          class="table">
                    <el-table-column type="selection"
                                     :reserve-selection="true">
                    </el-table-column>
                    <el-table-column prop="merchant_id"
                                     label="商戶ID"
                                     width="100"
                                     align="center" />
                    <el-table-column prop="name"
                                     label="商戶"
                                     width="100"
                                     align="center" />
                    <el-table-column prop="domain"
                                     label="域名"
                                     width="200"
                                     align="center" />
                    <el-table-column width="120"
                                     label="來源註記"
                                     align="center">
                        <template #default="scope">
                            <el-tooltip class="item"
                                        effect="dark"
                                        :content="scope.row.remark"
                                        placement="top">
                                <div class="tool-tip-text"> {{scope.row.remark}}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="note"
                                     label="備註"
                                     align="center" />
                    <el-table-column prop="source_name"
                                     label="網域來源"
                                     align="center" />
                    <el-table-column prop="domain_expires_at"
                                     label="到期時間"
                                     sortable="custom"
                                     width="180"
                                     align="center" />
                    <el-table-column label="自動續費"
                                     align="center">
                        <template #default="scope">
                            <div v-if="scope.row.is_renew_auto=='Y'">是</div>
                            <div v-else>否</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status"
                                     label="域名狀態"
                                     align="center" />
                    <el-table-column prop="updated_at"
                                     label="最後更新時間"
                                     width="180"
                                     align="center">

                    </el-table-column>
                    <el-table-column width="120"
                                     label="DNS指向"
                                     align="center">
                        <template #default="scope">
                            <el-tooltip class="item"
                                        effect="dark"
                                        placement="top">
                                <template #content> <template v-for="(server,idx) in scope.row.nameservers"
                                              :key="idx">
                                        {{server}}
                                        <br>
                                    </template>
                                </template>
                                <div class="tool-tip-text"
                                     v-html="nameSeverChange(scope.row.nameservers)"></div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="分享給"
                                     align="center">
                        <template #default="scope">

                            <div v-if="scope.row.share_list !== undefined &&scope.row.share_list.length>0">
                                <template v-for="(item, index) in scope.row.share_list"
                                          :key="index">
                                    <div>{{item.mid}}</div>
                                </template>
                            </div>
                            <div v-else>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="管理"
                                     align="center">
                        <template #default="scope"
                                  align="center">

                            <el-button v-if="scope.row.source_id!=2"
                                       size="mini"
                                       type="info"
                                       @click="detail(scope.$index, scope.row)">詳情</el-button>

                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pagination">
                <el-pagination background
                               layout=" prev, pager, next"
                               :current-page="pageIndex"
                               :page-size="pageSize"
                               :page-count="pageCount"
                               @current-change="handlePageChange">
                </el-pagination>
            </div>
            <el-dialog title="網域詳情"
                       v-model="editVisible"
                       width="480px"
                       center>
                <json-viewer :value="details"
                             :expand-depth=5
                             copyable
                             boxed
                             sort />
            </el-dialog>
            <!--DNS更改 -->
            <DNSdialog :dnsDialogVisible="dnsDialogVisible"
                       :selectionId="selectionId"
                       :selectionInfo="selectionInfo"
                       @close-dialog='dnsDialogVisible = false'></DNSdialog>
            <!--備註更新 -->
            <UpdateNotesDialog :callback="submitFinished"
                               :selectionId="selectionId"
                               :selectionInfo="selectionInfo"
                               @close-dialog="updateNotesDialogVisible = false"
                               :updateNotesDialogVisible="updateNotesDialogVisible"></UpdateNotesDialog>
        </div>
    </div>
</template>

<script>
import { domainList, exportNoteSample, exportNote, batchImportDomainNote, exportDomainList } from '../../api/index'
import { dateFormat } from '@/utils/dateFormat.js'
import DNSdialog from './components/DNSdialog.vue'
import UpdateNotesDialog from './components/UpdateNotesDialog.vue'
export default {
    components: {
        DNSdialog,
        UpdateNotesDialog,
    },
    data() {
        return {
            formInline: {
                name: '',
                source_id: [],
                status: [
                    'ACTIVE',
                    'AWAITING',
                    'CONFISCATED',
                    'DISABLED',
                    'EXCLUDED',
                    'FAILED',
                    'HELD',
                    'LOCKED',
                    'PARKED',
                    'PENDING',
                    'RESERVED',
                    'REVERTED',
                    'SUSPENDED',
                    'UNKNOWN',
                    'UNLOCKED',
                    'UNPARKED',
                    '正常',
                    '续费宽限期',
                    '转出中',
                    '正在过户',
                    '高价赎回期',
                    '出售中',
                    '正在竞价',
                    '等待确认',
                    '解析受限',
                ],
            },
            startDay: '',
            endDay: '',
            tableData: [],
            pageIndex: 1,
            pageSize: 10,
            pageCount: 0,
            totalCount: 0, //共幾筆?
            editVisible: false, //dilog顯示
            details: '', //詳情
            sourceList: {}, //網域來源
            statusList: {}, //域名狀態
            selectionId: [], //勾選到的list
            selectionInfo: [], //勾選到的所有資訊
            order: '', //排序
            dnsDialogVisible: false, //DNS彈窗
            updateNotesDialogVisible: false, //備註更新彈窗
            listLoading: false,
            notifyinstance: '', //提示實例
            disableDnsBtn: false, //DNS按鈕
            shortcuts: [
                {
                    text: '1日',
                    value: (() => {
                        const end = new Date()
                        const start = new Date()
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 1)
                        return [start, end]
                    })(),
                },
                {
                    text: '7日',
                    value: (() => {
                        const end = new Date()
                        const start = new Date()
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 7)
                        return [start, end]
                    })(),
                },
                {
                    text: '30日',
                    value: (() => {
                        const end = new Date()
                        const start = new Date()
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 30)
                        return [start, end]
                    })(),
                },
            ],
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.listLoading = true
            let sourceIdJson = JSON.stringify(this.formInline.source_id)
            let statusJson = JSON.stringify(this.formInline.status)
            if (this.formInline.datetime) {
                this.startDay = dateFormat(this.formInline.datetime[0])
                this.endDay = dateFormat(this.formInline.datetime[1])
            } else {
                this.startDay = ''
                this.endDay = ''
            }
            let data = {
                domain: this.formInline.domain,
                expired_at: this.startDay,
                expired_end: this.endDay,
                source_id: sourceIdJson,
                status: statusJson,
                share_from: this.formInline.share_from,
                share_to: this.formInline.share_to,
                remark: this.formInline.remark,
                note: this.formInline.note,
                order: this.order,
                page: this.pageIndex,
            }
            domainList(data)
                .then((res) => {
                    if (res.code == 300) {
                        this.tableData = res.data.data_list
                        this.pageCount = res.data.total_page
                        this.totalCount = res.data.total_count
                        this.sourceList = res.data.source_select_list
                        this.statusList = res.data.status_select_list
                        /* 為了預設 **/
                        // let presetList = this.statusList.filter((res) => res.value != 'UPDATED' && res.value != 'TRANSFERRED' && res.value != 'EXPIRED' && res.value != 'CANCELLED')
                        // let list = []
                        // presetList.map((o) => {
                        //     list.push(o.value)
                        // })
                        // this.formInline.status = list
                        /* 為了預設 **/
                    } else if (res.code == 500) {
                        this.$message.error(res.message)
                    }
                    this.listLoading = false
                })
                .catch(() => {
                    this.listLoading = false
                    this.$message.error('失敗')
                })
        },
        //查詢
        handleSearch() {
            this.getData()
        },
        //清除
        resetForm() {
            this.formInline = []
            window.history.pushState({}, 0, 'https://' + window.location.host + '/domainList')
        },
        changeSelect() {
            // console.log('123')
        },
        //詳情
        detail(index, row) {
            this.details = JSON.parse(row.original_data)
            this.editVisible = true
        },
        // 分页导航
        handlePageChange(val) {
            this.pageIndex = val
            this.getData()
        },
        //勾選key
        getRowKey(row) {
            return row.id
        },
        //有勾選到的
        handleSelectionChange(val) {
            this.selectionId = []
            this.selectionInfo = val
            val.forEach((element) => {
                this.selectionId.push(element.id)
            })
            const found = val.find((element) => element.source_name !== 'ClRo')
            if (found && !this.disableDnsBtn) {
                this.disableDnsBtn = true
            } else if (!found && this.disableDnsBtn) {
                this.disableDnsBtn = false
            }
        },
        batchImportAdds() {
            this.$refs['excel-upload-input'].click()
        },
        //批量備註匯入
        batchImportClick(e) {
            const files = e.target.files
            const rawFile = files[0]
            let formData = new FormData()
            formData.append('excel', rawFile)
            batchImportDomainNote(formData).then((res) => {
                this.excelTake(res, '回報備註結果')
                this.$refs['tag-excel-upload-input'].value = ''
            })
        },
        //excel備註範本
        excelEx() {
            exportNoteSample().then((res) => {
                this.excelTake(res, '備註範本下載')
            })
        },
        //匯出備註
        exportRemark() {
            exportNote({ domain_id_list: this.selectionId }).then((res) => {
                this.excelTake(res, '匯出備註')
            })
        },
        //開啟DNS修改彈窗
        updateDNS() {
            if (this.selectionInfo.length < 1) {
                this.$message({
                    message: '請勾選需處理之數據',
                    type: 'warning',
                })
                return
            }
            if (this.disableDnsBtn) {
                this.notifyinstance = this.$notify({
                    title: '警告',
                    message: '目前勾選的選項中，請先「清除勾選」，並選擇網域來源為『Clro』，再操作『DNS修改』',
                    type: 'warning',
                    duration: 0,
                })
                return
            }
            this.dnsDialogVisible = true
        },
        //開啟備註更新彈窗
        updateNotes() {
            if (this.selectionInfo.length < 1) {
                this.$message({
                    message: '請勾選需處理的備註',
                    type: 'warning',
                })
                return
            }
            this.updateNotesDialogVisible = true
        },
        //關閉備註彈窗
        submitFinished() {
            this.updateNotesDialogVisible = false
            this.resetSelect()
            this.getData()
        },
        //接收excel
        excelTake(res, title) {
            const xlsx = 'application/vnd.ms-excel'
            const blob = new Blob([res], { type: xlsx })
            const fileReader = new FileReader()
            let _this = this
            fileReader.readAsText(blob, 'utf-8')
            fileReader.onload = function () {
                if (res.type === 'application/json') {
                    if (JSON.parse(fileReader.result).code == 500) {
                        _this.$message.error('請勾選匯出的項目')
                    }
                } else {
                    const a = document.createElement('a') // 轉換完成，建立一個a標籤用於下載
                    a.download = `${title}.xlsx`
                    a.href = window.URL.createObjectURL(blob)
                    a.click()
                    a.remove()
                }
            }
        },
        //排序
        handleSortChange({ prop, order }) {
            if (order == 'descending') {
                this.order = prop + '_desc'
            } else if (order == 'ascending') {
                this.order = prop + '_asc'
            } else {
                this.order = ''
            }
            this.tableData = []
            this.getData()
        },
        //匯出網域列表
        exporDomian() {
            let sourceIdJson = JSON.stringify(this.formInline.source_id)
            let statusJson = JSON.stringify(this.formInline.status)
            if (this.formInline.datetime) {
                this.startDay = dateFormat(this.formInline.datetime[0])
                this.endDay = dateFormat(this.formInline.datetime[1])
            } else {
                this.startDay = ''
                this.endDay = ''
            }
            let data = {
                domain: this.formInline.domain,
                expired_at: this.startDay,
                expired_end: this.endDay,
                source_id: sourceIdJson,
                status: statusJson,
                share_from: this.formInline.share_from,
                share_to: this.formInline.share_to,
                note: this.formInline.note,
                order: this.order,
                page: this.pageIndex,
            }

            exportDomainList(data).then((res) => {
                this.excelTake(res, '網域列表')
            })
        },
        //dns指向更改
        nameSeverChange(array) {
            let a = array || []
            return a.join('<br>')
        },
        //清除勾選
        resetSelect() {
            this.$refs.domainTable.clearSelection()
        },
    },
}
</script>

<style lang="scss" scoped>
.count-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.table {
    margin-top: 35px;
}
@media screen and (max-width: 800px) {
    .el-dialog {
        width: 80% !important;
    }
}

// .cell {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: center;
// }
.span-color-primary {
    color: #409eff;
}
.span-color-error {
    color: #f56c6c;
}
.excel-upload-input {
    display: none;
}
.pd-top-bottom {
    padding: 20px 0px;
}
</style>
<style>
/* table 後面多一點空白解法 **/
.el-table__header col[name='gutter'] {
    display: table-cell !important;
}
.el-table__body {
    width: 100% !important ;
}
/* table 後面多一點空白解法 END**/
</style>